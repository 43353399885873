<template>
  <base-dialog
    no_icon
    title="Cambio de caja"
    basic_btn
    @open="open"
    @save="save"
    width="600"
    ref="dialog"
  >
    <v-row align="center">
      <v-col cols="8">
        <form action data-vv-scope="change-form">
          <v-row>
            <v-col cols="6" class="py-0">
              <label>Caja origen</label>
              <v-select
                :items="cashRegisters"
                v-model="change.cash_register_id"
                outlined
                dense
                hide-details
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('change-form.cash_register_id')"
                data-vv-name="cash_register_id"
                item-text="name"
                item-value="id"
                :class="[
                  {
                    'error--text':
                      change.cash_register_id &&
                      change.cash_register_id == change.new_cash_register_id,
                  },
                ]"
              ></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <label>Caja destino</label>
              <v-select
                :items="cashRegisters"
                v-model="change.new_cash_register_id"
                outlined
                dense
                hide-details
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="
                  errors.collect('change-form.new_cash_register_id')
                "
                data-vv-name="new_cash_register_id"
                item-text="name"
                item-value="id"
                :class="[
                  {
                    'error--text':
                      change.new_cash_register_id &&
                      change.cash_register_id == change.new_cash_register_id,
                  },
                ]"
              ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
              <label>Mes</label>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-on="on"
                    dense
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('change-form.month')"
                    data-vv-name="month"
                    :value="`${
                      $t('months')[new Date(change.month).getMonth() + 1]
                    } ${new Date(change.month).getFullYear()}`"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="change.month"
                  no-title
                  scrollable
                  color="primary"
                  type="month"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" class="py-0">
              <label>Tipo</label>
              <v-select
                outlined
                dense
                hide-details
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('change-form.type')"
                data-vv-name="type"
                :items="types"
                v-model="change.type"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="font-size: 17px; margin: auto">{{
                    item.text
                  }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="py-0">
              <label>Cantidad</label>
              <v-text-field
                outlined
                dense
                hide-details
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('change-form.amount')"
                data-vv-name="amount"
                :suffix="symbol"
                v-model="change.amount"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <label>¿Que gastos/ingresos quieres que se traspasen?</label>

              <v-select
                outlined
                dense
                hide-details
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('change-form.all')"
                data-vv-name="all"
                v-model="change.all"
                :items="change_all"
              ></v-select>
            </v-col>
          </v-row>
        </form>
      </v-col>
      <v-col cols="4">
        <v-card class="border_primary">
          <v-simple-table>
            <tbody>
              <tr>
                <td>Total</td>
                <td>{{ $n(total, "currency") }}</td>
              </tr>
              <tr>
                <td>Remaining</td>
                <td>{{ $n(remaining, "currency") }}</td>
              </tr>
              <tr>
                <td>Traspasado</td>
                <td>{{ $n(transfered, "currency") }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="change.all == false">
      <v-col cols="12">
        <v-data-table
          :items="expenses"
          :headers="headers"
          show-select
          v-model="change.ids"
          disable-pagination
        >
          <template v-slot:item.data="{ item }">
            <span
              v-bind:class="{
                'error--text': type == 'forecast' && today > item.data,
              }"
              >{{ $d(new Date(item.data), "dateShort") }}</span
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import * as moment from "moment";

export default {
  name: "ChangeCashRegister",
  data() {
    return {
      dialog: false,
      cashRegisters: [],
      menu: false,
      change: {
        cash_register_id: null,
        new_cash_register_id: null,
        month: null,
        type: "",
        amount: "",
        ids: [],
      },
      headers: [
        {
          text: this.$tc("expenses.concepto", 2),
          align: "center",
          sortable: false,
          value: "conceptos",
        },
        {
          text: this.$t("expenses.data"),
          align: "center",
          sortable: false,
          value: "data",
        },
        {
          text: this.$t("expenses.pay_mode"),
          align: "center",
          sortable: false,
          value: "pay_mode",
        },
        {
          text: this.$t("expenses.cantidad"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
      expenses: [],
      types: [
        { text: "%", value: "percentage", style: "font-size: 10px" },
        { text: "€", value: "money" },
      ],
      change_all: [
        { text: "Todos los gastos del mes", value: true },
        {
          text: "Quiero seleccionar yo los gastos que quiero que se traspasen",
          value: false,
        },
      ],
      total: 0,
    };
  },
  watch: {
    "change.all": {
      handler() {
        if (this.change.all == false) this.fetchExpenses();
        this.fetchAmount();
      },
      deep: true,
    },

    "change.month": {
      handler() {
        if (this.change.all == false) this.fetchExpenses();
        this.fetchAmount();
      },
      deep: true,
    },

    "change.cash_register_id": {
      handler() {
        if (this.change.all == false) this.fetchExpenses();
        this.fetchAmount();
      },
      deep: true,
    },
  },
  computed: {
    from() {
      let value = this.change.month.split("-");
      let from = new Date(value[0], value[1] - 1, 1);
      return `${from.getFullYear()}-${
        from.getMonth() + 1 >= 10
          ? +from.getMonth() + 1
          : "0" + (+from.getMonth() + 1)
      }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`;
    },
    to() {
      let value = this.change.month.split("-");
      let to = new Date(value[0], value[1], 0);
      return `${to.getFullYear()}-${
        to.getMonth() + 1 >= 10
          ? +to.getMonth() + 1
          : "0" + (+to.getMonth() + 1)
      }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`;
    },
    remaining() {
      return this.total - this.transfered;
    },
    transfered() {
      if (this.change.type == "percentage")
        return (this.total * this.change.amount) / 100;
      else if (this.change.type == "money") return this.change.amount;
      return 0;
    },
    ...mapState("expenses", ["filtersState"]),
    symbol() {
      let t = this.types.filter((t) => t.value == this.change.type);
      if (t) return t.text;
      return null;
    },
  },
  methods: {
    ...mapActions("cash_register", ["getCashRegisters"]),
    open() {
      console.log("OPEN");
      this.change = {
        cash_register_id: null,
        new_cash_register_id: null,
        month: null,
        type: "",
        amount: "",
        ids: [],
      };
      this.getCashRegisters({ pagination: { itemsPerPage: -1 } }).then(
        (res) => {
          this.cashRegisters = res.data;
        }
      );
      this.change.cash_register_id = this.filtersState.cash_register_id;
      this.change.month = moment().format("YYYY-MM");
    },
    async save() {
      this.errors.clear();
      this.userProcessed = false;
      let form_val = await this.$validator.validateAll("change-form");
      if (form_val) {
        console.log("VALIDADO");
        this.transferBetweenCashRegister(this.change).then((res) => {
          this.$emit("refresh");
          this.$refs.dialog.close();
        });
      }
    },
    ...mapActions("expenses", [
      "getTotalAmount",
      "transferBetweenCashRegister",
    ]),
    fetchExpenses() {
      console.log("LOAD EXPENSE");
      this.getExpenses({
        pagination: {
          itemsPerPage: -1,
        },
        filters: {
          cash_register_id: this.change.cash_register_id,
          from: this.from,
          to: this.to,
        },
      }).then((res) => {
        this.expenses = res.data;
      });
    },
    fetchAmount() {
      console.log("From", this.from);
      console.log("TO", this.to);
      this.getTotalAmount({
        filters: {
          cash_register_id: this.change.cash_register_id,
          from: this.from,
          to: this.to,
        },
      }).then((res) => {
        this.total = res;
      });
    },
  },
};
</script>
<style scoped lang="sass">
label
  display: block
  height: 25px
</style>