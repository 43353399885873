var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{ref:"dialog",attrs:{"no_icon":"","title":"Cambio de caja","basic_btn":"","width":"600"},on:{"open":_vm.open,"save":_vm.save}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('form',{attrs:{"action":"","data-vv-scope":"change-form"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('label',[_vm._v("Caja origen")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'error--text':
                    _vm.change.cash_register_id &&
                    _vm.change.cash_register_id == _vm.change.new_cash_register_id,
                } ],attrs:{"items":_vm.cashRegisters,"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.cash_register_id'),"data-vv-name":"cash_register_id","item-text":"name","item-value":"id"},model:{value:(_vm.change.cash_register_id),callback:function ($$v) {_vm.$set(_vm.change, "cash_register_id", $$v)},expression:"change.cash_register_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('label',[_vm._v("Caja destino")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                {
                  'error--text':
                    _vm.change.new_cash_register_id &&
                    _vm.change.cash_register_id == _vm.change.new_cash_register_id,
                } ],attrs:{"items":_vm.cashRegisters,"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.new_cash_register_id'),"data-vv-name":"new_cash_register_id","item-text":"name","item-value":"id"},model:{value:(_vm.change.new_cash_register_id),callback:function ($$v) {_vm.$set(_vm.change, "new_cash_register_id", $$v)},expression:"change.new_cash_register_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('label',[_vm._v("Mes")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.month'),"data-vv-name":"month","value":((_vm.$t('months')[new Date(_vm.change.month).getMonth() + 1]) + " " + (new Date(_vm.change.month).getFullYear())),"readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":"","color":"primary","type":"month"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.change.month),callback:function ($$v) {_vm.$set(_vm.change, "month", $$v)},expression:"change.month"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('label',[_vm._v("Tipo")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.type'),"data-vv-name":"type","items":_vm.types},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"17px","margin":"auto"}},[_vm._v(_vm._s(item.text))])]}},{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"17px","margin":"auto"}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.change.type),callback:function ($$v) {_vm.$set(_vm.change, "type", $$v)},expression:"change.type"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('label',[_vm._v("Cantidad")]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.amount'),"data-vv-name":"amount","suffix":_vm.symbol},model:{value:(_vm.change.amount),callback:function ($$v) {_vm.$set(_vm.change, "amount", $$v)},expression:"change.amount"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',[_vm._v("¿Que gastos/ingresos quieres que se traspasen?")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('change-form.all'),"data-vv-name":"all","items":_vm.change_all},model:{value:(_vm.change.all),callback:function ($$v) {_vm.$set(_vm.change, "all", $$v)},expression:"change.all"}})],1)],1)],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"border_primary"},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.$n(_vm.total, "currency")))])]),_c('tr',[_c('td',[_vm._v("Remaining")]),_c('td',[_vm._v(_vm._s(_vm.$n(_vm.remaining, "currency")))])]),_c('tr',[_c('td',[_vm._v("Traspasado")]),_c('td',[_vm._v(_vm._s(_vm.$n(_vm.transfered, "currency")))])])])])],1)],1)],1),(_vm.change.all == false)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.expenses,"headers":_vm.headers,"show-select":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:{
              'error--text': _vm.type == 'forecast' && _vm.today > item.data,
            }},[_vm._v(_vm._s(_vm.$d(new Date(item.data), "dateShort")))])]}}],null,false,3034675212),model:{value:(_vm.change.ids),callback:function ($$v) {_vm.$set(_vm.change, "ids", $$v)},expression:"change.ids"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }